import React,{Fragment, useState} from 'react'
import axios from "axios";
import {Link, navigate} from 'gatsby'
import Seo from "../../components/seo/seo";
import TextBox from "../../components/inputs/Textbox";
import SelectBox from "../../components/inputs/SelectBox";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
import countryList from '../../components/json/countryList'
import industryList from '../../components/json/businessList'
import logo from '../../assets/img/virsat-logo-light.svg'
import { StaticImage } from "gatsby-plugin-image";

function NewsLetter({location}) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});

  if (location.state){

    console.log(location.state)
  }

  const [txtValues, setTxtValues] = useState({
    name: "",
    company: "",
    email: location.state && location.state.email ? location.state.email: "",
  });

  const [selectValues, setSelectValues] = useState({
    country:"",
    industry:"",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  const handleSelectChange = e => {
    setSelectValues({ ...selectValues, [e.name]: e.id })
  }

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = {...txtValues, ...selectValues}

    const body = JSON.stringify({ ...data });

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/newsletter-form`,
        body,
        config
      );
      
      if (res.data.status) {
        resetValues()
        const details = "You have been added to our mailing list and will now be among the first to hear about the latest innovation, new product release, offers and promotions, and upcoming events."
        navigate("/lp/thank-you/", {
          state:{details}
        })
      } else {
        setErrors(res.data.errors);
      }
      
      setSending(false);
    } catch (err) {
      setSending(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };

  const handleClose = () => {
    setErrors({});
  };

  const resetValues = ()=> {
    setErrors({});
    setTxtValues({
      name: "",
      company: "",
      email: "",
    });
    setSelectValues({
      country:"",
      industry:"",
    })
  }

  return (
    <Fragment>
      {sending && <Loading message="Please wait while sending your request." />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Seo
        title='Newsletter | VIRSAT - Virtual Reality Safety Training'
        description='Discover the latest innovation, new products released, offers and promotions, and upcoming events.'
      />
      
      <div className="main-container newsletter-lp">
        <div className="newsletterlp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Newsletter Background'
            placeholder='blurred'
            objectFit
            quality="100"
          />
        </div>
        <div className="row">
          <div className="column show-overflow">
            <div className="form-bg-container">
              <StaticImage
                src='../../assets/img/pages/newsletter/virsat-form-newsletter-bg.jpg'
                alt='VIRSAT Logo'
                placeholder='blurred'
                objectFit
                quality="100"
                style={{borderRadius:"15px"}}
                imgStyle={{borderRadius:"15px"}}
              />
            </div>
            <div className="logo-container">
              <Link to="/">
                <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
              </Link>
            </div>
            <div className="form-container">
              <h1 className="main-title small light">Subscribe to Newsletter</h1>
              <p className="light description justify">Discover the latest innovation, new products released, offers and promotions, and upcoming events.</p>
              <form action='' method='post' onSubmit={handleSubmit}>
                <div className='label-container'>
                  <TextBox
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Your Name*'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.name}
                    required
                    error={errors.name}
                  />
                  {errors.name && (
                    <div className='field-error secondary-color'>{errors.name[0]}</div>
                  )}
                </div>
                <div className='label-container'>
                  <TextBox
                    type='text'
                    id='company'
                    name='company'
                    placeholder='Company Name*'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.company}
                    required
                    error={errors.company}
                  />
                  {errors.company && (
                    <div className='field-error secondary-color'>{errors.company[0]}</div>
                  )}
                </div>
                <div className='label-container'>
                  <TextBox
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Company Email*'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.email}
                    required
                    error={errors.email}
                  />
                  {errors.email && (
                    <div className='field-error secondary-color'>{errors.email[0]}</div>
                  )}
                </div>
                <div className='label-container'>
                  <SelectBox
                    dropdownList={industryList}
                    label="Industry"
                    searchTxt="Search Industry"
                    id="industry"
                    name="industry"
                    selectClass="regular-text"
                    headerStyle={{width:'100%'}}
                    handleSelectBox={handleSelectChange}
                    selectBoxValue={selectValues.industry}
                  />
                  {errors.industry && (
                    <div className='field-error secondary-color'>{errors.industry[0]}</div>
                  )}
                </div>
                <div className='label-container'>
                  <SelectBox
                    dropdownList={countryList}
                    label="Country"
                    searchTxt="Search Country"
                    id="country"
                    name="country"
                    selectClass="regular-text"
                    headerStyle={{width:'100%'}}
                    handleSelectBox={handleSelectChange}
                    selectBoxValue={selectValues.country}
                  />
                  {errors.country && (
                    <div className='field-error secondary-color'>{errors.country[0]}</div>
                  )}
                </div>
                <button type='submit' className="full-width">Subscribe Me</button>

                <p className="light justify privacy-policy">We hate spam and promise to keep your email address safe. Check out our <Link to="/privacy-policy/" className="secondary"><strong>Privacy Policy</strong></Link>.</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NewsLetter
